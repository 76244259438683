import request from '@/utils/request'

// 查询菜单权限（含children）
export function findMenuPermissions(slug) {
  return request({
    url: `/permissions/menus/${slug}`,
    method: 'get'
  })
}

// 查询权限（不含children）
export function findPermissions(slug) {
  return request({
    url: `/permissions/${slug}`,
    method: 'get'
  })
}

// 更新页面权限
export function updatePagePermissions(slug, data) {
  return request({
    url: `/permissions/${slug}/pages`,
    method: 'put',
    data
  })
}

// 更新可登录平台权限
export function updatePlatformPermissions(slug, data) {
  return request({
    url: `/permissions/${slug}/platforms`,
    method: 'put',
    data
  })
}
