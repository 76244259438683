<template>
  <div>
    <a-modal
      v-model="isShow"
      centered
      :mask-closable="false"
      :ok-button-props="{ props: { loading: submitting } }"
      width="600px"
      title="编辑角色"
      @ok="handleSubmit"
    >
      <a-form
        class="custom-compact-form"
        :form="form"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 15 }"
        @submit="handleSubmit"
      >
        <a-form-item label="角色名称">
          <a-input
            :disabled="isCanNotEdit"
            v-decorator="['name', {
              normalize: this.$lodash.trim,
              rules: [
                { required: true, message: '请输入角色名称' },
                { max: 20, message: '最多20个字符' },
              ]
            }]"
            autocomplete="off"
          />
        </a-form-item>
        <a-form-item label="说明">
          <a-input
            v-decorator="['remark', {
              normalize: this.$lodash.trim,
              rules: [
                { message: '请输入说明描述' },
                { max: 200, message: '最多200个字符' },
              ]
            }]"
            autocomplete="off"
          />
        </a-form-item>

        <a-form-item label="上级角色">
          <a-select
            :disabled="isCanNotEdit"
            v-decorator="['parent_role_id']"
            :allow-clear="true"
          >
            <a-select-option
              v-for="option in parentRoleOptions"
              :key="option.id"
              :value="option.id"
            >
              {{ option.name }}
            </a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item label="任务权限">
          <a-select
            mode="multiple"
            v-decorator="['task_authority']"
          >
            <a-select-option
              v-for="option in taskAuthorityOptions"
              :key="option.value"
              :value="option.value"
            >
              {{ option.label }}
            </a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item label="跟踪订单">
          <a-select
            mode="multiple"
            v-decorator="['order_authority']"
          >
            <a-select-option value="service">
              殡仪服务订单
            </a-select-option>
            <a-select-option value="subscribe">
              附加产品订单
            </a-select-option>
            <a-select-option value="additional">
              殡仪服务新增项订单
            </a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item label="可结算订单">
          <a-checkbox
            v-decorator="['settle_authority', { valuePropName: 'checked' }]"
          />
        </a-form-item>
        <a-form-item label="可开单">
          <a-checkbox
            v-decorator="['appointment_authority', { valuePropName: 'checked' }]"
          />
        </a-form-item>
        <a-form-item label="可播放视频">
          <a-checkbox
              v-decorator="['video_authority', { valuePropName: 'checked' }]"
          />
        </a-form-item>
        <a-form-item label="可登录平台">
          <a-checkbox-group
            v-decorator="['access_platform']"
            style="width: 100%;"
          >
            <a-row>
              <a-col
                :span="12"
                v-for="option in AccessPlatformOptions"
                :key="option.endpoint"
              >
                <a-checkbox
                  :value="option.endpoint"
                >
                  {{ option.name }}
                </a-checkbox>
              </a-col>
            </a-row>
          </a-checkbox-group>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import {
  updateRole, findTenantRoleForm, findTenantParentRoleOptions,
  findRoleTaskAuthorityOptions
} from '@/api/role'
import { findPermissions } from '@/api/permission'
export default {
  name: 'EditRole',
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    id: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      form: this.$form.createForm(this, { name: 'role_edit' }),
      parentRoleOptions: [],
      taskAuthorityOptions: [],
      submitting: false,
      AccessPlatformOptions: [],
      data: {},
      isCanNotEdit: false
    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    }
  },
  created() {
    this.fetchTenantParentRoleOptions()
    this.fetchTaskAuthorityOptions()
    this.fetchAccessPlatformOptions()
    this.fetchFormData()
    this.fetchAccessPlatformFormData()
  },
  methods: {
    fetchFormData() {
      findTenantRoleForm(this.id).then((res) => {
        if (res.code === 0) {
          this.data = res.data
          this.isCanNotEdit =
              ['tenant_manager', 'tenant_finance', 'tenant_administrative', 'tenant_customer', 'tenant_service', 'tenant_talk']
                .includes(this.data.slug)

          this.form.setFieldsValue({
            name: this.data.name,
            remark: this.data.remark,
            parent_role_id: this.data.parent_role_id === 0 ? undefined : this.data.parent_role_id,
            task_authority: this.data.task_authority ? this.data.task_authority : undefined,
            order_authority: this.data.order_authority ? this.data.order_authority : undefined,
            settle_authority: this.data.settle_authority,
            appointment_authority: this.data.appointment_authority,
            video_authority: this.data.video_authority
          })
        }
      })
    },

    fetchAccessPlatformFormData() {
      findPermissions(this.id).then((res) => {
        if (res.code === 0) {
          this.form.setFieldsValue({
            access_platform: this.$lodash.map(res.data.platforms, 'endpoint')
          })
        }
      })
    },

    fetchTenantParentRoleOptions() {
      findTenantParentRoleOptions({ id: this.id }).then((res) => {
        this.parentRoleOptions = res.data
      })
    },

    fetchTaskAuthorityOptions() {
      findRoleTaskAuthorityOptions().then((res) => {
        this.taskAuthorityOptions = res.data
      })
    },

    // 加载可登入平台选项
    fetchAccessPlatformOptions() {
      findPermissions('tenant_admin').then((res) => {
        this.AccessPlatformOptions = res.data.platforms
      })
    },

    handleSubmit(e) {
      e.preventDefault()
      if (this.submitting) {
        this.$warning({
          title: '请勿重复提交',
          content: ''
        })
        return
      }
      this.form.validateFields((err, values) => {
        if (!err) {
          this.submitting = true
          updateRole(this.id, values).then((res) => {
            if (res.code === 0) {
              // 关闭模态框
              this.isShow = false
              // 告知父组件已完成
              this.$emit('completed')
            }
            this.submitting = false
          })
        }
      })
    }
  }
}
</script>
